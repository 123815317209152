<script setup lang="ts">
import type { BasePageProps } from '@/scripts/types/pages'
import SliptreeLogo from '@/svg/brand/sliptree-logo.svg'
import AuthLayout from '@/views/layouts/AuthLayout.vue'
import LanguageSelector from '@/views/pages/Auth/Partials/LanguageSelector.vue'
import { Head, Link, useForm, usePage } from '@inertiajs/vue3'
import { route } from 'momentum-trail'
import { computed, ref } from 'vue'

defineOptions({
    layout: AuthLayout,
})

defineProps<{
    canResetPassword: boolean
    status?: string
}>()

const invitation = usePage<BasePageProps>().props.auth.invitation

const form = useForm({
    email: invitation?.email || '',
    password: '',
    remember: false,
})

function submit() {
    form.transform(data => ({
        ...data,
        remember: form.remember ? 'on' : '',
    })).post(route('login'), {
        onFinish: () => form.reset('password'),
    })
}

const langSelector = ref<InstanceType<typeof LanguageSelector>>()
const homePath = computed(() => (langSelector.value?.currentLanguage === 'en' ? '/' : `/${langSelector.value?.currentLanguage}`))
</script>

<template>
    <div class="mx-auto w-full max-w-sm">
        <header>
            <Head :title="$t('Sign In')" />

            <a :href="homePath"><SliptreeLogo class="h-12 w-auto" /></a>

            <ErrorAlert v-if="form.errors.email" class="mt-6">
                <p>{{ form.errors.email }}</p>
            </ErrorAlert>

            <SuccessAlert v-if="status" class="my-6">
                {{ status }}
            </SuccessAlert>

            <h2 class="mt-6 text-3xl font-semibold leading-9 text-gray-900">
                {{
                    invitation?.company
                        ? $t('Sign in to join :company', { company: invitation.company.name })
                        : $t('Sign in to your account')
                }}
            </h2>

            <p class="max-w mt-2 text-sm leading-5 text-gray-600">
                {{ $t('Or') }}
                <Link
                    :href="route('register')"
                    class="font-semibold text-pink-600 transition duration-150 ease-in-out hover:text-pink-500 focus:underline focus:outline-none"
                >
                    {{ $t('start your 14-day free trial') }}
                </Link>
            </p>
        </header>

        <div class="mt-8">
            <!-- TODO: include social login here {@itambek 2023-02-10} -->

            <div class="mt-6">
                <form class="grid grid-cols-1 gap-6" @submit.prevent="submit">
                    <div class="form-group">
                        <FormLabel target="email" :value="$t('E-mail address')" />
                        <FormInput v-model="form.email" name="email" type="email" required autofocus autocomplete="username" />
                    </div>

                    <div class="form-group">
                        <FormLabel target="password" :value="$t('Password')" />
                        <FormInput v-model="form.password" name="password" type="password" required autocomplete="current-password" />
                    </div>

                    <div class="grid grid-cols-2 gap-6">
                        <div class="row-start-1 mt-0">
                            <FormLabel class="flex flex-row items-center gap-2" target="remember">
                                <FormCheckbox id="remember" v-model="form.remember" />
                                {{ $t('Remember me') }}
                            </FormLabel>
                        </div>

                        <div class="col-span-2 row-start-2">
                            <Button
                                type="submit"
                                size="lg"
                                class="flex w-full justify-center"
                                :loading="form.processing"
                                dusk="sign-in-button"
                            >
                                {{ $t('Sign In') }}
                            </Button>
                        </div>

                        <div v-if="canResetPassword" class="row-start-1 text-right text-sm leading-5">
                            <Link
                                :href="route('password.request')"
                                class="text-pink-600 transition duration-150 ease-in-out hover:text-pink-500 focus:underline focus:outline-none"
                            >
                                {{ $t('Forgot your password?') }}
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <LanguageSelector ref="langSelector" />
    </div>
</template>
